import React from "react"

import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const ChecklistItem = ({ number, title, text }) => {
  return (
    <li css={styles.listItem}>
      <span css={styles.number}>{number}</span>
      <div>
        <h4 css={styles.title}>{title}</h4>
        <p>{text}</p>
      </div>
    </li>
  )
}

const styles = {
  listItem: css({
    display: "flex",
    alignItems: "flex-start",
    paddingTop: theme.gapDouble,
    paddingBottom: theme.gapDouble,
    marginBottom: 0,
    borderBottom: `2px solid ${theme.colours.lightGrey}`,
  }),
  number: css({
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    flexShrink: 0,
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "50%",
    backgroundColor: theme.colours.red,
    color: theme.colours.white,
    marginRight: 12,
  }),
  title: css({
    fontSize: "1.5rem",
    color: theme.colours.red,
    marginBottom: theme.gap,
  }),
}

export default ChecklistItem
