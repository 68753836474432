import React from "react"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import DefaultLayoutSidebar from "../components/DefaultLayoutSidebar"
import CustomNavTitle from "../components/CustomNavTitle"
import ChecklistItem from "../components/ChecklistItem"

import illustration2x from "../images/illustrations/illustration-checklist@2x.png"

import { theme } from "../styles/theme"

const BedsideChecklist = ({ location }) => {
  const listData = [
    {
      title: "Check for blood component integrity",
      text:
        "No clots, leaks, damage, discolouration and check expiry (midnight unless otherwise stated).",
    },
    {
      title: "Check informed consent is documented",
      text:
        "Reason & risk/benefits explained? Alternatives? Information given?",
    },
    {
      title: "Confirm positive patient identification (PPID)",
      text: "Ask your patient to tell you their full name and DOB.",
    },
    {
      title:
        "Check unit tag against unit label, prescription, patient's ID band and patient's verbal response",
      text: "Including specific requirements.",
    },
    {
      title: "Perform observations",
      text:
        "Baseline observations within 60 minutes before starting the transfusion.",
    },
  ]

  return (
    <Layout>
      <SEO title="Bedside Checklist" />

      <CustomNavTitle
        title="Bedside Checklist"
        destination="/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="max-w-content">
          <p css={theme.components.title} className="mt-gap mb-gap">The bedside check is the last chance to prevent incorrect component transfusion incidents.</p>
          <p css={theme.components.title} className="mt-gap mb-gap">
            Ensure the patient is in a suitable environment for transfusion, all
            necessary equipment is available, and the patient is prepared, e.g.
            IV access, immediately before collecting the component.
          </p>
          <p css={theme.components.title} className="mt-gap mb-gap">
          In the event of a delay, components should be returned to the laboratory immediately for appropriate temperature-controlled storage. N:B: Clinical drug fridges are not suitable for blood components.
          </p>          
          <p css={theme.components.title} className="mt-gap mb-gap">
            Before each unit of blood is transfused, ensure you complete the
            following at the patient's side:
          </p>

          <hr css={styles.divider} />

          <ol>
            {listData.map((item, index) => (
              <ChecklistItem
                key={index}
                number={index + 1}
                title={item.title}
                text={item.text}
              />
            ))}
          </ol>

          <p css={{ margin: theme.gap }}>
            Local policy will indicate whether 1 or 2 person independent
            checking is required and how to document completion of the
            checklist.
          </p>
        </section>

        <aside>
          <img src={illustration2x} alt="illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

const styles = {
  divider: css({
    border: `2px solid ${theme.colours.lightGrey}`,
    marginTop: theme.gapDouble,
    marginBottom: theme.gap,
  }),
}

export default BedsideChecklist
